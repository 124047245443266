import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"

const ArchiveCaseStudy = props => {
  const ArchiveCaseStudyItem = (props, index) => {
    const item = props.data.data
    let description = item.description.text
    if (item.description.text.length > 200)
      description = item.description.text.substring(0, 200) + "..."
    return (
      <Link
        to={`/inspiration/case-study/${props.data.uid}/`}
        className="group col-span-2 text-left border-l border-gold mb-12"
        title={`${item.title.text}`}
      >
        <Fade>
          <img
            src={item.featured_image.url}
            srcSet={`${
              item.featured_image.fluid ? item.featured_image.fluid.srcSet : ""
            }`}
            alt={item.title.text}
            className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70"
          />
          <div className="p-6 pb-0">
            <h2 className="lg:h-8 text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
              {props.data.data.title.text}
            </h2>
            <div className="description">
              <p>{description}</p>
            </div>
          </div>
        </Fade>
      </Link>
    )
  }

  const ArchiveCaseStudyContainer = props => {
    return (
      <div className="container pt-12 case-study-items">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
          {props.data.map((post, index) => {
            return <ArchiveCaseStudyItem data={post.node} key={index} />
          })}
        </div>
      </div>
    )
  }

  const crumbs = [
    { label: "Inspiration", href: "/inspiration" },
    { label: "Case Studies", href: "/inspiration/case-studies", active: true },
  ]

  // For some reason returning null prints null in the below <Seo> component so I'm using this workaround with a space in the string
  const titleType = typeof props.pageContext.type === 'string' ? `${props.pageContext?.type?.replace("Doors", "Door")} ` : ''

  return (
    <article className="case-study">
      <Seo title={`${titleType}Case Studies`} />
      <div className="pt-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <main className={`bg-offwhite`}>
        <div className="container py-12 text-center">
          <Fade>
            <h1 className="font-display text-gold rfs:text-5xl">
              Case Studies
            </h1>
            <div className="w-12 separator mt-5"></div>
          </Fade>
        </div>
        <div className="w-full bg-white py-6">
          <div className="container py-0 overflow-x-auto">
            <Fade>
              <div className="space-x-4 w-max m-auto">
                <Link
                  to="/inspiration/case-studies/"
                  className="btn font-bold text-xxs whitespace-nowrap"
                  activeClassName="active"
                >
                  All posts
                </Link>
                <Link
                  to="/inspiration/case-studies/internal-doors/"
                  className="btn font-bold text-xxs whitespace-nowrap"
                  activeClassName="active"
                >
                  Internal Doors
                </Link>
                <Link
                  to="/inspiration/case-studies/front-doors/"
                  className="btn font-bold text-xxs whitespace-nowrap"
                  activeClassName="active"
                >
                  Front Doors
                </Link>
                <Link
                  to="/inspiration/case-studies/garage-doors/"
                  className="btn font-bold text-xxs whitespace-nowrap"
                  activeClassName="active"
                >
                  Garage Doors
                </Link>
              </div>
            </Fade>
          </div>
        </div>
        <ArchiveCaseStudyContainer
          data={props.data.allPrismicCaseStudy.edges}
        />
      </main>
    </article>
  )
}

export default ArchiveCaseStudy

export const ArchiveBlogPostQuery = graphql`
  query ArchiveCaseStudyQuery($type: [String!]!) {
    allPrismicCaseStudy(
        filter: { tags: { in: $type } },
        sort: {fields: first_publication_date, order: DESC}
      ) {
      edges {
        node {
          uid
          data {
            featured_image {
              alt
              url
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            title {
              text
            }
            description {
              richText
              text
            }
          }
        }
      }
    }
  }
`
